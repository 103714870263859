export const COIN_LIST_REQUEST = 'COIN_LIST_REQUEST'
export const COIN_LIST_SUCCESS = 'COIN_LIST_SUCCESS'
export const COIN_LIST_FAIL = 'COIN_LIST_FAIL'

export const COIN_STATUS_REQUEST = 'COIN_STATUS_REQUEST'
export const COIN_STATUS_SUCCESS = 'COIN_STATUS_SUCCESS'
export const COIN_STATUS_FAIL = 'COIN_STATUS_FAIL'

export const COIN_SEARCH_REQUEST = 'COIN_SEARCH_REQUEST'
export const COIN_SEARCH_SUCCESS = 'COIN_SEARCH_SUCCESS'
export const COIN_SEARCH_FAIL = 'COIN_SEARCH_FAIL'

